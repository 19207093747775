<template>
  <div class="container" :style="{ minWidth: `${widthPdf}px` }">
    <v-progress-linear class="mt-1" indeterminate color="primary" v-if="loading"></v-progress-linear>
    <pdf
      v-for="i in numPages"
      :key="i"
      :src="src"
      :page="i"
      style="display: inline-block;width:100%; height:auto;margin:2px 0;"
      @loaded="loading = false"
    ></pdf>
    <div class="button__box">
      <button @click="pluspdfwidth(widthPdf)">+</button>
      <button @click="minspdfwidth(widthPdf)">-</button>
    </div>
  </div>
</template>

<script>
import pdf from 'vue-pdf'
export default {
  props: ['fileUrl'],
  components: {
    pdf: pdf
  },
  data () {
    return {
      numPages: 0,
      loading: false,
      src: undefined,
      widthPdf: 700
    }
  },
  mounted () {
    this.loading = true
    this.src = pdf.createLoadingTask(this.fileUrl)
    this.src.promise.then(pdf => {
      this.numPages = pdf.numPages
    })
  },
  beforeDestroy () {
    this.src = ''
  },
  methods: {
    pluspdfwidth (item) {
      if (item < 2500) {
        this.widthPdf += 100
      }
    },
    minspdfwidth (item) {
      if (item > 500) {
        this.widthPdf -= 100
      }
    }
  }
}
</script>

<style>
span.annotationLayer {
  border: 1px solid rgba(0, 0, 0, 0.5) !important;
}
.button__box{
  position: fixed;
  bottom: 60px;
  right: 10px;
  background-color: #424242;
  z-index: 9999;
  display: none;
  flex-direction: column;
  border-radius: 0 !important;
}
@media only screen and (max-width: 1299px){
  .button__box{
    display: flex;
  }
}
.button__box button{
  padding: 14px 15px;
  color: #fff;
  font-size: 24px;

}
.button__box button:hover{
  background-color: #707070;
}
</style>
